import React, { useContext, useState, useEffect } from "react";
import { EmplContext } from "../Login_Page/Login_Page/LoginPage";
import configData from "../../config.json";
import Base64Viewer from "../Display_file/Base64Viewer";

function Company() {
  const [fileUrl, setFileUrl] = useState('');
  const [fileError, setFileError] = useState(null);
  const { resultData } = useContext(EmplContext);
  const employee_id = resultData.employee_id || 0;

  

  const fetchFileUrl = async (index) => {
    
      
      try {
        const secretKey = configData.SECRET_Key;
        const requestData = {employee_id, secretKey };
        const response = await fetch(`${configData.SERVER_URL}/companyPolicy`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        });
        const data = await response.json();
        const result = JSON.parse(data.result);
        console.log("result ===", result);
        console.log("result.attach ===", result.attach);
        // const fileBase64 = data.attach; // Base64 string
        // const mimeType = "application/pdf"; // Adjust MIME type if needed
        // const fileSrc = `data:${mimeType};base64,${fileBase64}`;
        // data:application/pdf;base64,${atob(base64Code)}
        setFileUrl(result.attach); // Set the Base64 URI
        console.log("fileUrl ===", fileUrl);
      } catch (error) {   
        console.error("Error:", error);
      }
    
  };

  useEffect(() => {
    fetchFileUrl();
  }, [employee_id]);
  // if(`data:application/pdf;base64,${atob(fileUrl)}`){
  return (
    <div>
      
      
      <iframe
            src={`data:application/pdf;base64,${atob(fileUrl)}`}
            height="100%"
            width="380%"
            title="PDF Preview"
            
          ></iframe>
           
                                            
    </div>
  );
// }
  // else if (`data:image/jpeg;base64,${atob(fileUrl)}`){
    // return (
    //   <div>
        
        
    //     <iframe
    //           src={`data:image/jpeg;base64,${atob(fileUrl)}`}
    //           height="100%"
    //           width="380%"
    //           title="Image Preview"
              
    //         ></iframe>
             
                                              
    //   </div>
    // );
  // }else{
    // <p>UNSUPPORTED FILE</p>
  // }
}

export default Company;
