import React, { useState, useContext, useEffect } from "react";
import { Container, Dropdown } from "react-bootstrap";
import "./Header.css";
import { EmplContext } from "../Login_Page/Login_Page/LoginPage";
import configData from "../../../src/config.json";
import { ColorContext } from "../../ColorContext";
import Customize from "../CustomizeColor_Page/Customize";
import $ from "jquery";
import Swal from "sweetalert2";
import ManualAttendance from "../Manual-Attendance_Page/Manual-Attendance";
import eventEmitter from "../../Eventemitter";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// import Badge from "react-bootstrap/Badge";
import Badge from "@mui/material/Badge";
import MailIcon from "@mui/icons-material/Mail";
import Typography from "@mui/joy/Typography";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import Expenserequest from "../Expenses_page/Expenserequest";
import Button from "react-bootstrap/Button";

let openLeaveModal;
export default function Header({ setIsHr, isHr, sharedState, ismanager, setIsmanager }) {
  const {
    isAssetsModalOpen,
    setIsAssetsModalOpen,
    assetsFormData,
    setAssetsFormData,
    handleStateChange,
  } = sharedState;

  const [halfDayClicked, setHalfDayClicked] = useState(false);
  // const [startDate, setStartDate] = useState(getFormattedDate());
  // const [endDate, setEndDate] = useState(getFormattedDate());
  // const [daysDifference, setDaysDifference] = useState(1);
  const { resultData, setAuthenticated, isemphr, isempwfh, isempmanager } =
    useContext(EmplContext);
  const employee_id = resultData.employee_id || 0;
  const [emplinfo, setEmplinfo] = useState({
    profile_image: null,
    // company_image: null,
  });
  const { bgColor, textColor, pageColor } = useContext(ColorContext);
  const [assets, setAssets] = useState([]);
  const [requirementsAssets, setRequirementsAssets] = useState([]);
  const [replacementsAssets, setReplacementsAssets] = useState([]);

  const [remainingleaves, setRemainingLeaves] = useState("");

  // const showCustomization = () => {
  //   setIsVisible(true);
  // };

  const [exceptionsremaining, setExceptionsRemaining] = useState(0);

  const [modalShow, setModalShow] = useState(false);
  

  useEffect(() => {
    const storedIsM = localStorage.getItem("isManager") === "true";
    setIsmanager(storedIsM);
  }, [setIsmanager]);

  const handleRadioManagerChange = (event) => {
    const isMSelected = event.target.value === "Manager";
    setIsmanager(isMSelected);
    localStorage.setItem("isManager", isMSelected);
  };

  useEffect(() => {
    const storedIsHr = localStorage.getItem("isHr") === "true";
    setIsHr(storedIsHr);
  }, [setIsHr]);

  const handleRadioChange = (event) => {
    const isHrSelected = event.target.value === "HR";
    setIsHr(isHrSelected);
    localStorage.setItem("isHr", isHrSelected);
  };

  const handleLogout = () => {
    setAuthenticated(false);
    localStorage.setItem("authenticated", JSON.stringify(false));
    localStorage.removeItem("authenticated");
    localStorage.removeItem("username");
    localStorage.removeItem("pin");
  };

  const fetchCompanyDetails = async () => {
    // console.log ("called")
    try {
      const secretKey = configData.SECRET_Key;
      const requestData = { employee_id, secretKey };
      const response = await fetch(`${configData.SERVER_URL}/mycompany`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
      const data = await response.json();
      const result = JSON.parse(data.result);
      // console.log("result", result);
      if (result.success === true) {
        setEmplinfo({
          profile_image: result.profile_image,
          // company_image: result.company_image,
        });
        setRequirementsAssets(result.all_assets_list);
        setReplacementsAssets(result.all_assets_owned_list);
        setAssets(result.all_assets_owned_list);
        setAssetsFormData((prevData) => ({
          ...prevData,
          name: resultData.name,
        }));
        setRemainingLeaves(result.virtual_remaining_leaves);
        setExceptionsRemaining(result.exception_count);
      }else{
        console.log("result ===", result);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchCompanyDetails();
  }, [employee_id]);

  const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);
  let openLeaveModal = (date) => {
    setIsLeaveModalOpen(true);
  };
  
  const closeLeaveModal = (e) => {
    setIsLeaveModalOpen(false);
    closeForm();
  };

  const toggleHalfDayClicked = () => setHalfDayClicked(!halfDayClicked);

  const openAssetsModal = () => {
    setIsAssetsModalOpen(true);
  };

  const [formData, setFormData] = useState({
    leaveType: "",
    dateFrom: "",
    dateTo: "",
    halfDay: false,
    shift: "",
    duration: 0,
    reason: "",
    attachment: null,
  });

  const closeForm = () => {
    setFormData({
      leaveType: "",
      dateFrom: "",
      dateTo: "",
      halfDay: false,
      shift: "",
      duration: 0,
      reason: "",
      attachment: null,
    });
    setHalfDayClicked(false);
  };

  const [fetchleave, setFetchleave] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    // console.log("ev",e)

    if (fetchleave) return; // Prevent multiple requests

    setFetchleave(true); // Set fetchleave to true to indicate the request is in progress

    try {
      // console.log("formData====", formData);
      const secretKey = configData.SECRET_Key;
      const requestData = { formData, employee_id, secretKey };
      // console.log("formdata", formData)
      const response = await fetch(`${configData.SERVER_URL}/leave`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        throw new Error("Failed to create leave");
      }
      if (response) {
        const data = await response.json();
        // console.log("data:--", data)
        const result = JSON.parse(data.result);
        // console.log("result:--", result);
        // console.log("result.success:--", result.success)

        if (result.success === true) {
          // Swal.fire({
          //   icon: "success",
          //   title: result.message,
          // });
          window.location.reload();
        } else {
          Swal.fire({
            icon: "error",
            title: result.message,
          });
        }
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        icon: "error",
        title: error,
      });
    } finally {
      setFetchleave(false); // Reset fetchleave to false after the request is completed
    }
  };

  const navigateToMyProfile = ()=> {
    navigate("/myprofile");
  }
  const handleChange = (e) => {
    const { name, checked, files, value } = e.target;
    // console.log("name, checked, value====",name, checked, value)
    if (e.target.name === "attachment") {
      const base64DataArray = [];

      // Read each file and push its base64 representation to base64DataArray
      Array.from(files).forEach((file) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          base64DataArray.push(reader.result);

          // Check if all files have been processed
          if (base64DataArray.length === files.length) {
            // Update the state after all files have been read
            setFormData({ ...formData, [name]: base64DataArray });
          }
        };
        reader.readAsDataURL(file);
        // setSelectedFile((prevSelectedFiles) => [
        //   ...prevSelectedFiles,
        //   file.name,
        // ]);
      });
    } else if (name === "halfDay") {
      // updatedFormData = {
      //   ...formData,
      //   [name]: checked,
      // };
      setFormData({ ...formData, [name]: checked === true ? checked : false });
      toggleHalfDayClicked();
      // if (checked) {
      //   // showShift();
      // } else {
      //   // hideShift();
      // }
    } else {
      let updatedFormData = { ...formData, [name]: value };
      // console.log("updatedFormData",updatedFormData)
      // console.log("name,value",name,value)
      if (name === "dateFrom" || name === "dateTo") {
        const startDate = new Date(updatedFormData.dateFrom);
        const endDate = new Date(updatedFormData.dateTo);

        if (name === "dateTo") {
          endDate.setHours(23, 59, 59, 999);
        }

        const totalDays = Math.round(
          (endDate - startDate) / (1000 * 60 * 60 * 24)
        );

        let weekDays = 0;
        if (name === "dateFrom") {
          for (let i = 0; i <= totalDays; i++) {
            const currentDate = new Date(startDate);
            currentDate.setDate(currentDate.getDate() + i);
            if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
              weekDays++;
            }
          }
        } else if (name === "dateTo") {
          for (let i = 0; i < totalDays; i++) {
            const currentDate = new Date(startDate);
            currentDate.setDate(currentDate.getDate() + i);
            if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
              weekDays++;
            }
          }
        }
        updatedFormData.duration = weekDays.toString();
      }
      setFormData(updatedFormData);
    }
  };

  const [selectedFile, setSelectedFile] = useState([]);

  useEffect(() => {
    if (assetsFormData.requestType === "Replacements") {
      setAssets(replacementsAssets);
    } else if (assetsFormData.requestType === "Requirements") {
      setAssets(requirementsAssets);
    }
  }, [assetsFormData.requestType]);

  useEffect(() => {
    const value = assetsFormData.asset;
    // console.log("assetsFormData.asset====", assetsFormData.asset);
    const asset = assets.find((assetItem) => assetItem[2] === value);
    if (asset) {
      setAssetsFormData({
        ...assetsFormData,
        assetCode: asset[1],
        noOfAsset: asset[3],
      });
    }
  }, [assetsFormData.asset]);

  const closeAssetsModal = (e) => {
    setIsAssetsModalOpen(false);
    setSelectedFile([]);
    setAssetsFormData({
      ...assetsFormData,
      name: "",
      requestType: "Replacements",
      employeeCode: "",
      asset: "",
      assetCode: "",
      noOfAsset: "",
      reason: "",
      photos: null,
    });
  };
  const handleAssetFormDataChange = (e) => {
    const { name, files, value } = e.target;
    if (name === "photos") {
      const base64DataArray = [];

      // Read each file and push its base64 representation to base64DataArray
      Array.from(files).forEach((file) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          base64DataArray.push(reader.result);

          // Check if all files have been processed
          if (base64DataArray.length === files.length) {
            // Update the state after all files have been read
            setAssetsFormData({ ...assetsFormData, [name]: base64DataArray });
          }
        };
        reader.readAsDataURL(file);
        setSelectedFile((prevSelectedFiles) => [
          ...prevSelectedFiles,
          file.name,
        ]);
      });
    } else {
      setAssetsFormData({ ...assetsFormData, [name]: value });
    }
  };

  const handleAssetsSubmit = async (e) => {
    e.preventDefault();

    // console.log("assetsFormData====", assetsFormData);
    try {
      const secretKey = configData.SECRET_Key;
      const requestData = { ...assetsFormData, employee_id, secretKey };
      // console.log("formdata",formData)
      const response = await fetch(`${configData.SERVER_URL}/asset_request`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        throw new Error("Failed to request for asset");
      }
      if (response) {
        const data = await response.json();
        // console.log("data:--",data)
        const result = JSON.parse(data.result);
        // closeForm();
        // console.log("result:--",result)
        // console.log("result.success:--",result.success)

        if (result.success === true) {
          setIsAssetsModalOpen(false);
          Swal.fire({
            icon: "success",
            title: result.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: result.message,
          });
        }
      }
      // closeForm();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // console.log('selectedFile====',selectedFile)
  function showReplace() {
    $(".form-replace").show();
    // $(".form-replace").css("display", "block");
  }

  function closeReplace() {
    $(".form-replace").hide();
    setAssetsFormData({
      ...assetsFormData,
      requestType: "Requirements",
      photos: null,
    });
  }

  const handleDrop = (event) => {
    // console.log("called");
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setSelectedFile(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  // console.log("count======", exceptionsremaining);
  useEffect(() => {
    const handleCountUpdate = (newCount) => {
      setExceptionsRemaining(newCount);
    };

    eventEmitter.on("countUpdated", handleCountUpdate);

    // Cleanup listener on component unmount
    return () => {
      eventEmitter.off("countUpdated", handleCountUpdate);
    };
  }, []);

  const navigate = useNavigate();

  const callAutoLeave = () => {
    navigateToMyAttendances().then(() => {
      eventEmitter.emit("clickAutoleave");
    });
  };

  const navigateToMyAttendances = () => {
    return new Promise((resolve) => {
      navigate("/myattendances");
      // Use a timeout to simulate waiting for navigation to complete
      setTimeout(() => {
        resolve();
      }, 0); // Adjust the timeout duration if necessary
    });
  };

  const togglesidebar = () => {
    // console.log("called");
    eventEmitter.emit("toggleSidebar");
  };

  return (
    <>
      <nav className="navbar p-0 fixed-top d-flex flex-row">
        <div className="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center">
          <img
            src="company_logo.png"
            alt="Profile Pic"
            className="img-xs brand-logo-image"
          />
        </div>
        <div
          className="navbar-menu-wrapper flex-grow d-flex align-items-stretch"
          // style={{backgroundColor: bgColor}}
        >
          {/* <button
          className="navbar-toggler navbar-toggler align-self-center"
          type="button"
          data-toggle="minimize"
          
        >
          <i className="fas fa-bars" style={{ color: "#039cad" }} />
          <span className="mdi mdi-menu" />
        </button> */}

          {/* <ul className="navbar-nav w-100">
            <li className="nav-item w-100">
              <form
                className="nav-link mt-2 mt-md-0 d-none d-lg-flex search"
                onSubmit={handleLogin}
              >
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search... "
                />
              </form>
            </li>
          </ul> */}

          <ul className="navbar-nav navbar-nav-right">
            <li className="nav-item">
              {isempwfh && (
                // <li
                //   className="nav-item menu-items"
                // >
                <ManualAttendance />
                // </li>
              )}
            </li>

            <li className="nav-item dropdown d-none d-lg-block">
              <div className="dropdown" style={{ position: "relative" }}>
                {exceptionsremaining !== 0 ? (
                  <Badge
                    color="error"
                    badgeContent={exceptionsremaining}
                    data-bs-toggle="dropdown"
                  >
                    <button
                      className="nav-link create-new-button "
                      type="button"
                      aria-expanded="false"
                      style={{
                        paddingRight: "15px",
                        backgroundColor: textColor, borderRadius:"12px"
                      }}
                    >
                      + Raise a request
                    </button>
                  </Badge>
                ) : (
                  <button
                    className="nav-link create-new-button "
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{ paddingRight: "15px", backgroundColor: textColor  ,borderRadius:"12px"}}
                  >
                    + Raise a request
                  </button>
                )}

                <ul
                  className="dropdown-menu dropdown-menu-end px-2"
                  style={{ position: "absolute", top: "46px", right: "0px", borderRadius:"20px"}}
                >
                  <li className="d-flex flex-column justify-content-center align-items-center mb-2">
                    <button
                      style={{ width: "11rem", backgroundColor: textColor ,borderRadius:"12px"}}
                      className="btn btn-primary "
                      type="button"
                      onClick={openLeaveModal}
                    >
                      Apply a Leave
                    </button>
                  </li>
                  <li className="d-flex flex-column justify-content-center align-items-center mb-2">
                    <button
                      style={{ width: "11rem", backgroundColor: textColor ,borderRadius:"12px"}}
                      className="btn btn-primary "
                      type="button"
                      onClick={openAssetsModal}
                    >
                      Request for Assets
                    </button>
                  </li>
                  <li className="d-flex flex-column justify-content-center align-items-center mb-2">
                    <Button
                      style={{ width: "11rem", backgroundColor: textColor ,borderRadius:"12px"}}
                      className="btn btn-primary "
                      variant="primary"
                      onClick={() => setModalShow(true)}
                    >
                      Request for Expense
                    </Button>
                  </li>
                  {exceptionsremaining !== 0 && (
                    <>
                      {/* <li className="d-flex flex-column justify-content-center align-items-center mb-2">
                        <button
                          style={{ width: "11rem", backgroundColor: textColor }}
                          className="btn btn-primary "
                          type="button"
                          onClick={callAutoLeave}
                        >
                          Raise Exception{" "}
                          <Badge pill={exceptionsremaining} bg="danger">
                            {exceptionsremaining}
                          </Badge>
                        </button>
                      </li> */}
                      <li className="d-flex flex-column justify-content-center align-items-center mb-2">
                        <button
                          style={{ width: "11rem", backgroundColor: textColor ,borderRadius:"12px"}}
                          className="btn btn-primary "
                          type="button"
                          onClick={callAutoLeave}
                        >
                          Raise Exception{" "}
                          {/* <Badge
                            color="error"
                            badgeContent={exceptionsremaining}
                          >
                            <NotificationsActiveOutlinedIcon />
                          </Badge> */}
                          <Badge
                            color="error"
                            badgeContent={exceptionsremaining}
                          >
                            <Typography fontSize="xl">🔔</Typography>
                          </Badge>
                        </button>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </li>

            <li className="nav-item dropdown d-block d-lg-none">
              <div className="dropdown" style={{ position: "relative" }}>
                {exceptionsremaining !== 0 ? (
                  <Badge
                    color="error"
                    badgeContent={exceptionsremaining}
                    data-bs-toggle="dropdown"
                  >
                    <button
                      className="nav-link create-new-button "
                      type="button"
                      aria-expanded="false"
                      style={{
                        paddingRight: "15px",
                        backgroundColor: textColor,
                      }}
                    >
                      <i className="fa-solid fa-plus"></i>
                    </button>
                  </Badge>
                ) : (
                  <button
                    className="nav-link create-new-button "
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{ paddingRight: "15px", backgroundColor: textColor }}
                  >
                    <i className="fa-solid fa-plus"></i>
                  </button>
                )}

                <ul
                  className="dropdown-menu dropdown-menu-end px-2"
                  style={{ position: "absolute", top: "46px", right: "0px" }}
                >
                  <li className="d-flex flex-column justify-content-center align-items-center mb-2">
                    <button
                      style={{ width: "11rem", backgroundColor: textColor }}
                      className="btn btn-primary "
                      type="button"
                      onClick={openLeaveModal}
                    >
                      Apply a Leave
                    </button> 
                  </li>
                  <li className="d-flex flex-column justify-content-center align-items-center mb-2">
                    <button
                      style={{ width: "11rem", backgroundColor: textColor }}
                      className="btn btn-primary "
                      type="button"
                      onClick={openAssetsModal}
                    >
                      Request for Assets
                    </button>
                  </li>

                  <li className="d-flex flex-column justify-content-center align-items-center mb-2">
                    <Button
                      style={{ width: "11rem", backgroundColor: textColor }}
                      className="btn btn-primary "
                      variant="primary"
                      onClick={() => setModalShow(true)}
                    >
                      Request for Expense
                    </Button>
                  </li>
                  {exceptionsremaining !== 0 && (
                    <>
                      {/* <li className="d-flex flex-column justify-content-center align-items-center mb-2">
                        <button
                          style={{ width: "11rem", backgroundColor: textColor }}
                          className="btn btn-primary "
                          type="button"
                          onClick={callAutoLeave}
                        >
                          Raise Exception{" "}
                          <Badge pill={exceptionsremaining} bg="danger">
                            {exceptionsremaining}
                          </Badge>
                        </button>
                      </li> */}
                      <li className="d-flex flex-column justify-content-center align-items-center mb-2">
                        <button
                          style={{ width: "11rem", backgroundColor: textColor }}
                          className="btn btn-primary "
                          type="button"
                          onClick={callAutoLeave}
                        >
                          Raise Exception{" "}
                          {/* <Badge
                            color="error"
                            badgeContent={exceptionsremaining}
                          >
                            <NotificationsActiveOutlinedIcon />
                          </Badge> */}
                          <Badge
                            color="error"
                            badgeContent={exceptionsremaining}
                          >
                            <Typography fontSize="xl">🔔</Typography>
                          </Badge>
                        </button>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </li>
            <Expenserequest
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
            {/* <li className="nav-item nav-settings d-none d-lg-block">
              <a className="nav-link" href="#">
                <i className="mdi mdi-view-grid" />
              </a>
            </li> */}
            {/* <li className="nav-item dropdown">
                <a
                  className="nav-link count-indicator dropdown-toggle"
                  id="notificationDropdown"
                  href="#"
                  data-toggle="dropdown"
                >
                  <i className="far fa-bell text-dark" />
                  <span className="count bg-danger" />
                </a>
                <div
                  className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list"
                  aria-labelledby="notificationDropdown"
                >
                  <h6 className="p-3 mb-0">Notifications</h6>
                  <div className="dropdown-divider" />
                  <a className="dropdown-item preview-item">
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-dark rounded-circle">
                        <i className="mdi mdi-calendar text-success" />
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <p className="preview-subject mb-1">Event today</p>
                      <p className="text-muted ellipsis mb-0">
                        {" "}
                        Just a reminder that you have an event today{" "}
                      </p>
                    </div>
                  </a>
                  <div className="dropdown-divider" />
                  <a className="dropdown-item preview-item">
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-dark rounded-circle">
                        <i className="mdi mdi-settings text-danger" />
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <p className="preview-subject mb-1">Settings</p>
                      <p className="text-muted ellipsis mb-0">
                        {" "}
                        Update dashboard{" "}
                      </p>
                    </div>
                  </a>
                  <div className="dropdown-divider" />
                  <a className="dropdown-item preview-item">
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-dark rounded-circle">
                        <i className="mdi mdi-link-variant text-warning" />
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <p className="preview-subject mb-1">Launch Admin</p>
                      <p className="text-muted ellipsis mb-0"> New admin wow! </p>
                    </div>
                  </a>
                  <div className="dropdown-divider" />
                  <p className="p-3 mb-0 text-center">See all notifications</p>
                </div>
              </li> */}

            <li className="nav-item dropdown">
              <Container className="drop-down-logout">
                <Dropdown>
                  <Dropdown.Toggle variant=" primary" id="dropdown-basic">
                    {emplinfo.profile_image ? (
                      <img
                        src={`data:image/jpeg;base64,${atob(
                          emplinfo.profile_image
                        )}`}
                        // src={
                        //   emplinfo.profile_image}
                        alt={`Profile-Image`}
                        className="img-xs rounded-circle"
                      />
                    ) : (
                      <img
                        className="img-xs rounded-circle"
                        src="vnn_1.jpg"
                        alt=""
                      />
                    )}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {/* <Dropdown.Item onClick={handleLogout} >
                      <span className="nav-link">
                    logout
                    </span>
                    </Dropdown.Item> */}

                    {/* <Dropdown.Item >
                      <span onClick={navigateToMyProfile} className="nav-link">
                      My Profile
                      </span>
                      </Dropdown.Item> */}
                    {/* style={{ backgroundColor: bgColor }} */}
                    {isemphr && (
                      <div
                        className="btn-group mx-2 mt-2"
                        role="group"
                        aria-label="Basic radio toggle button group "
                      >
                        <input
                          type="radio"
                          className="btn-check"
                          name="btnradio"
                          id="btnradio1"
                          autoComplete="off"
                          value="HR"
                          checked={isHr}
                          onChange={handleRadioChange}
                        />
                        <label
                          className="btn btn-outline-primary rounded-left"
                          htmlFor="btnradio1"
                        >
                          HR
                        </label>

                        <input
                          type="radio"
                          className="btn-check"
                          name="btnradio"
                          id="btnradio3"
                          autoComplete="off"
                          value="Employee"
                          checked={!isHr}
                          onChange={handleRadioChange}
                        />
                        <label
                          className="btn btn-outline-primary rounded-right"
                          htmlFor="btnradio3"
                        >
                          Employee
                        </label>
                      </div>
                    )}

                    {isempmanager && (
                      <div
                        className="btn-group mx-2 mt-2"
                        role="group"
                        aria-label="Basic radio toggle button group "
                      >
                        <input
                          type="radio"
                          className="btn-check"
                          name="btnradio-manager"
                          id="btnradio-manager"
                          autoComplete="off"
                          value="Manager"
                          checked={ismanager}
                          onChange={handleRadioManagerChange}
                        />
                        <label
                          className="btn btn-outline-primary rounded-left"
                          htmlFor="btnradio-manager"
                        >
                          Manager
                        </label>

                        <input
                          type="radio"
                          className="btn-check"
                          name="btnradio-manager"
                          id="btnradio-me"
                          autoComplete="off"
                          value="Me"
                          checked={!ismanager}
                          onChange={handleRadioManagerChange}
                        />
                        <label
                          className="btn btn-outline-primary rounded-right"
                          htmlFor="btnradio-me"
                        >
                          Me
                        </label>
                      </div>
                    )}
                    {/* <Dropdown.Divider />
                    <Dropdown.ItemText style={{ color: "black" }}>
                      Settings
                    </Dropdown.ItemText> */}
                    {/* <Dropdown.Item onClick={showCustomization}>
                      Color Customization
                    </Dropdown.Item> */}

                    {/* <Dropdown.Item>
                      <span
                        className=""
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasRight"
                        aria-controls="offcanvasRight"
                      >
                        Color Customization
                      </span>
                    </Dropdown.Item> */}

                    <Dropdown.Item onClick={handleLogout} >
                      <span className="nav-link">
                      Logout
                    </span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Container>
            </li>
            <li className="nav-item dropdown">
              <>
                <div
                  className="offcanvas offcanvas-end"
                  tabIndex={-1}
                  id="offcanvasRight"
                  aria-labelledby="offcanvasRightLabel"
                  data-bs-scroll="true"
                  data-bs-backdrop="false"
                >
                  <div className="offcanvas-header">
                    <h5 id="offcanvasRightLabel">Customize the theme</h5>
                    <button
                      type="button"
                      className="btn-close text-reset"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    />
                  </div>
                  <div className="offcanvas-body">
                    <Customize />
                  </div>
                </div>
              </>
            </li>
          </ul>
          <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            // data-toggle="offcanvas"
            onClick={togglesidebar}
          >
            <i className="fas fa-bars" style={{ color: "#039cad" }} />
          </button>
        </div>
        {isLeaveModalOpen && (
          <div
            className="modal"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
            onClick={closeLeaveModal}
          >
            <div
              className="modal-dialog"
              style={{ maxWidth: 750 }}
              onClick={(e) => e.stopPropagation()}
            >
              <div
                className="modal-content"
                style={{ backgroundColor: "#fff" }}
              >
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Apply Leave
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={closeLeaveModal}
                  />
                </div>
                <form action="" method="post" onSubmit={handleLogin}>
                  <div className="modal-body o_form_view">
                    <table className="tb-view">
                      <tbody className="tb_body_content">
                        <tr className="td_leave">
                          <td className="htd">Time Off Type</td>
                          <td style={{ width: "100%", position: "relative" }}>
                            <div className="">
                              <select
                                id="leaveType"
                                name="leaveType"
                                value={formData.leaveType}
                                onChange={handleChange}
                                required
                              >
                                <option value=""></option>
                                <option value="1">
                                  Paid Leave
                                  {/* ({remainingleaves} remaining out of{" "}
                                  {resultData.allocation_display}) */}
                                </option>
                                <option value="4">Unpaid Leave</option>
                               
                              </select>
                            </div>
                          </td>
                        </tr>
                        <tr className="td_leave">
                          <td className="htd">Dates</td>
                          <td style={{ width: "100%" }}>
                            <div className="divhtd">
                              <span className="htds">From </span>
                              <input
                                type="date"
                                id="dateFrom"
                                name="dateFrom"
                                value={formData.dateFrom}
                                onChange={handleChange}
                                required
                                // className="form-control"
                              />
                              {halfDayClicked ? (
                                <div
                                  className="leave_application"
                                  style={{ gap: "5px" }}
                                >
                                  <select
                                    id="shift"
                                    name="shift"
                                    value={formData.shift}
                                    onChange={handleChange}
                                    required={formData.halfDay}
                                    // className="form-control"
                                  >
                                    <option value=""></option>
                                    <option value="am">Morning</option>
                                    <option value="pm">Evening</option>
                                  </select>
                                </div>
                              ) : (
                                <div className="leave_application">
                                  <span className="htds pl-2">To </span>
                                  <input
                                    type="date"
                                    id="dateTo"
                                    name="dateTo"
                                    value={formData.dateTo}
                                    onChange={handleChange}
                                    required
                                    // className="form-control"
                                  />
                                </div>
                              )}
                            </div>
                            <div className="divhtd">
                              <div className="mr-2 mt-1">
                                <input
                                  type="checkbox"
                                  id="halfDay"
                                  name="halfDay"
                                  checked={formData.halfDay}
                                  onChange={handleChange}
                                  // className="form-control"
                                />
                              </div>
                              <label
                                className="o_form_label"
                                htmlFor="halfDay"
                                data-original-title=""
                                title=""
                              >
                                Half Day
                              </label>
                            </div>
                          </td>
                        </tr>
                        {halfDayClicked === false && (
                          <tr className="td_leave">
                            <td className="htd">Duration</td>
                            <td style={{ width: "100%" }}>
                              <input
                                type="number"
                                id="duration"
                                name="duration"
                                value={formData.duration}
                                readOnly
                                // className="form-control"
                              />
                            </td>
                          </tr>
                        )}
                        <tr className="td_leave">
                          <td className="htd">Description</td>
                          <td style={{ width: "100%" }}>
                            <textarea
                              id="o_field_input_19"
                              // className="o_field_text o_field_widget o_quick_editable o_input"
                              name="reason"
                              value={formData.reason}
                              onChange={handleChange}
                              // required
                              // className="form-control"
                            />
                          </td>
                        </tr>
                        <tr className="td_leave">
                          <td className="htd">Attachment</td>
                          <td style={{ width: "100%", textAlign: "left" }}>
                            <input
                              type="file"
                              id="attachment"
                              name="attachment"
                              accept=".pdf, image/*"
                              onChange={handleChange}
                              multiple
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                      onClick={closeLeaveModal}
                    >
                      Discard
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Apply
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}

        {isAssetsModalOpen && (
          <div
            className="modal"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
            onClick={closeAssetsModal}
          >
            <div
              className="modal-dialog"
              style={{ maxWidth: 750 }}
              onClick={(e) => e.stopPropagation()}
            >
              <div
                className="modal-content"
                style={{ backgroundColor: "#fff" }}
              >
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Request Asset
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={closeAssetsModal}
                  />
                </div>
                <form
                  action=""
                  onSubmit={handleAssetsSubmit}
                  className="assetsForm"
                >
                  <div className="modal-body o_form_view">
                    <table className="tb-view" style={{ width: "90%" }}>
                      <tbody className="assetsModalForm">
                        <tr>
                          <td>
                            <label htmlFor="Name">Name</label>
                            <input
                              type="text"
                              id="Name"
                              name="name"
                              value={assetsFormData.name}
                              onChange={handleAssetFormDataChange}
                              className=""
                              placeholder="Name"
                              readOnly
                            />
                          </td>
                          <td>
                            <label htmlFor="requestType">Request Type</label>
                            <select
                              id="requestType"
                              name="requestType"
                              value={assetsFormData.requestType}
                              // onChange={handleAssetFormDataChange}
                              onChange={(e) => {
                                handleAssetFormDataChange(e);
                                if (e.target.value === "Requirements") {
                                  closeReplace();
                                  setSelectedFile([]);
                                } else if (e.target.value === "Replacements") {
                                  showReplace();
                                }
                              }}
                            >
                              <option value="Replacements">Replacements</option>
                              <option value="Requirements">Requirements</option>
                            </select>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label htmlFor="employeeCode">Employee Code</label>
                            <input
                              type="text"
                              id="employeeCode"
                              name="employeeCode"
                              value={assetsFormData.employeeCode}
                              onChange={handleAssetFormDataChange}
                              className=""
                              placeholder="ON12354"
                            />
                          </td>
                          <td>
                            <label htmlFor="asset">Asset</label>
                            <select
                              name="asset"
                              id="asset"
                              onChange={handleAssetFormDataChange}
                              value={assetsFormData.asset}
                              required
                            >
                              <option key="empty" value="">
                                -- Select an Asset --
                              </option>
                              {assets.map((asset, idx) => (
                                <option key={idx} value={asset[2]}>
                                  {asset[0]}
                                </option>
                              ))}
                            </select>
                          </td>
                        </tr>
                        {assetsFormData.requestType === "Replacements" && (
                          <>
                            <tr>
                              <td>
                                <label htmlFor="assetCode">Asset Code</label>
                                <input
                                  type="text"
                                  id="assetCode"
                                  name="assetCode"
                                  value={assetsFormData.assetCode}
                                  onChange={handleAssetFormDataChange}
                                  readOnly
                                  className=""
                                  placeholder="NN0003"
                                />
                              </td>
                              <td>
                                <label htmlFor="reason">Reason</label>
                                <input
                                  style={{ width: "90%" }}
                                  type="text"
                                  id="reason"
                                  name="reason"
                                  value={assetsFormData.reason}
                                  onChange={handleAssetFormDataChange}
                                  required
                                />
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>

                    {/* {assetsFormData.requestType === "Replacements" && ( */}
                    <>
                      <div
                        style={{
                          marginBottom: 10,
                          marginTop: 20,
                          textAlign: "left",
                          fontSize: 16,
                          fontWeight: 700,
                        }}
                        className="form-replace"
                      >
                        Upload Files
                      </div>
                      <div className="formbold-file-input form-replace">
                        <input
                          type="file"
                          id="photos"
                          name="photos"
                          onChange={handleAssetFormDataChange}
                          accept="image/jpeg, image/png"
                          multiple
                          required={
                            assetsFormData.requestType === "Replacements"
                          }
                          // required
                        />
                        <label htmlFor="photos">
                          <div onDrop={handleDrop} onDragOver={handleDragOver}>
                            {selectedFile.length === 0 ? (
                              <>
                                <span className="formbold-drop-file">
                                  Drop files here
                                </span>
                                <span className="formbold-or"> OR </span>
                                <span className="formbold-browse">
                                  Browse files
                                </span>
                              </>
                            ) : (
                              selectedFile.map((file, idx) => (
                                <p key={idx}>{file}</p>
                              ))
                            )}
                          </div>
                        </label>
                      </div>
                    </>
                    {/* )} */}
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                      onClick={closeAssetsModal}
                    >
                      Discard
                    </button>
                    <button
                      type="submit"
                      data-bs-dismiss="modal"
                      className="btn btn-primary"
                    >
                      Request
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </nav>
    </>
  );
}

// export default Header;
export { openLeaveModal};
