import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Routes, Navigate } from "react-router-dom";
import Sidebar from "../SideBar/Sidebar";
import Header from "../Header/Header";
import HomePage from "../HomePage/HomePage";
import Attendance from "../Attendance_Page/Attendance";
import Holiday from "../Holiday_Page/Holiday";
import MyAsset from "../Asset_Page/MyAsset";
import MyTimesheet from "../Timesheet_Page/MyTimesheet";
import PendingApproval from "../Timesheet_Page/PendingApproval";
import Approved from "../Timesheet_Page/Approved";
import Hrdashboard from "../HrDashboard/HrDashboard";
import { ColorContext } from "../../ColorContext";
import Customize from "../CustomizeColor_Page/Customize";
import { EmplContext } from "../Login_Page/Login_Page/LoginPage";
import useSharedState from "../useSharedState/useSharedState";
import ManualAttendance from "../Manual-Attendance_Page/Manual-Attendance";
import MyExpenses from "../Expenses_page/MyExpenses";
import ManagerDashboard from "../ManagerDashboard/ManagerDashboard";
import Document from "../Document_Page/Document";
import Profile from "../Profile_page/Profile";
import Company from "../Company_page/Company_policies";
import SidebarManager from "../SideBar/SidebarManager";
import AllLeaveRequest from "../ManagerDashboard/AllLeaveRequest";
import TeamExpenses from "../ManagerDashboard/TeamExpenses";
import TeamAssets from "../ManagerDashboard/TeamAssets";
import TeamTimesheet from "../ManagerDashboard/TeamTimesheet";
// import { Header } from "../Header/Header";
// import Company from "../Company_page/Company_policies";

function Maindashboard() {
  const [isHr, setIsHr] = useState(false);
  const [ismanager, setIsmanager] = useState(false);

  const { isemphr, isempmanager } = useContext(EmplContext);

  const [sharedFunction, setSharedFunction] = useState(null);

  const sharedState = useSharedState();

  return (
    <>
      <div className="container-scroller">
        <Header
          setIsHr={setIsHr}
          isHr={isHr}
          sharedState={sharedState}
          ismanager={ismanager}
          setIsmanager={setIsmanager}
        />
        {isHr && isemphr ? (
          <div className="main-panel">
            <Hrdashboard />
          </div>
        ) : ismanager && isempmanager ? (
          <>
            <SidebarManager />
            <div className="container-fluid page-body-wrapper">
              <Routes>
                <Route
                  index
                  element={<ManagerDashboard setSharedFunction={setSharedFunction} />}
                />
                <Route path="/AllLeaveRequest" element={<AllLeaveRequest />} />
                <Route path="/TeamExpenses" element={<TeamExpenses />} />
                <Route path="/TeamAssets" element={<TeamAssets />} />
                <Route path="/TeamTimesheet" element={<TeamTimesheet />} />
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </div>
          </>
        ) : (
          <>
            <Sidebar />
            <div className="container-fluid page-body-wrapper">
              <Routes>
                <Route
                  index
                  element={<HomePage setSharedFunction={setSharedFunction} />}
                />
                <Route
                  path="/myattendances"
                  element={
                    <Attendance
                      sharedFunction={sharedFunction}
                      setSharedFunction={setSharedFunction}
                    />
                  }
                />
                <Route path="/holidays" element={<Holiday />} />
                <Route
                  path="/myasset"
                  element={<MyAsset sharedState={sharedState} />}
                />
                <Route
                  path="/myexpenses"
                  element={<MyExpenses sharedState={sharedState} />}
                />
                <Route path="/mytimesheet" element={<MyTimesheet />} />
                <Route path="/pendingApproval" element={<PendingApproval />} />
                <Route path="/approved" element={<Approved />} />
                <Route path="/myprofile" element={<Profile />} />
                <Route path="/companypolicies" element={<Company />} />
                <Route
                  path="/documents"
                  element={<Document />}
                  sharedState={sharedState}
                />
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
              {/* {isVisible && (
                <div 
                // ref={customizeRef} 
                className="d-flex">
                <Customize />
                </div>
              )} */}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Maindashboard;
