import "./App.css";
import "./css/style.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "./css/remaining.css";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import LoginPage from "./components/Login_Page/Login_Page/LoginPage";
import Password from "./components/Login_Page/Login_Page/ForgotPassword";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ColorProvider } from "./ColorContext";
import Dropdown from "@mui/joy/Dropdown";
import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import Typography from "@mui/joy/Typography";


function App3({ children }) {
  return (
    <ColorProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="App">
          <Routes>
            <Route path="*" element={<LoginPage />} />
            {/* <Route path="*" element={<ResetPin />}/> */}
            <Route path="/forget_password" element={<Password />} />
          </Routes>
        </div>
      </LocalizationProvider>
    </ColorProvider>
  );
}

export default App3;
