import React, { useContext, useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import "./Profile.css";
import { EmplContext } from "../Login_Page/Login_Page/LoginPage";
import { useNavigate } from "react-router-dom";
import configData from "../../config.json";
import Swal from "sweetalert2";
import Asset_loading from "../Asset_Page/Asset_loading";
import { ColorContext } from "../../ColorContext";
import Base64Viewer from "../Display_file/Base64Viewer";

function Profile() {
  const navigate = useNavigate();
  const { resultData } = useContext(EmplContext);
  const { bgColor, textColor } = useContext(ColorContext);

  const employee_id = resultData.employee_id || 0;
  const jobRef = useRef(null);
  const generalRef = useRef(null);
  const [highlight, setHighlight] = useState(false);
  const [statelist, setStatelist] = useState([]);
  const [activeMenu, setActiveMenu] = useState(null);
  const [load, setLoading] = useState(true);

  const handleJobClick = () => {
    if (jobRef.current) {
      jobRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      setActiveMenu("job");

      // jobRef.current.focus();
    }
  };

  const handlegenClick = () => {
    if (generalRef.current) {
      generalRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      setActiveMenu("general");

      // jobRef.current.focus();
    }
  };

  const handleInfoClick = () => {
    // setHighlight(true);
    // setTimeout(() => {
    //   setHighlight(false);
    // }, 500);
  };

  const fetchData = async () => {
    try {
      const secretKey = configData.SECRET_Key;
      const requestData = { employee_id, secretKey };
      const response = await fetch(`${configData.SERVER_URL}/mydetails`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
      //   const data = await response.json();
      //   const result = JSON.parse(data.result);
      //   if (result.success === true) {
      //     setDetails(result);
      //   }
      if (response) {
        const data = await response.json();
        const result = JSON.parse(data.result);
        if (result.success === true) {
          for (let key in result) {
            const name = key;
            const value = result[key];
            setFormData((prevState) => ({ ...prevState, [name]: value }));
          }
          setInitialData(result); // Store the initial values for comparison
        } else {
          console.log("result ===", result);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  // Utility function to filter changed fields
  const getChangedFields = () => {
    const changedFields = {};
    for (let key in formData) {
      if (formData[key] !== initialData[key]) {
        changedFields[key] = formData[key];
      }
    }
    return changedFields;
  };

  const updateData = async () => {
    try {
      const changedFields = getChangedFields();

      const secretKey = configData.SECRET_Key;
      // Include employee_id and secretKey even if they are unchanged
      const requestData = {
        ...changedFields,
        employee_id,
        secretKey,
      };
      //   const requestData = { ...formData, employee_id, secretKey };
      const response = await fetch(`${configData.SERVER_URL}/updatedetails`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
      const data = await response.json();
      const result = JSON.parse(data.result);
      if (result.success === true) {
        //   setDetails(result);
        Swal.fire({
          icon: "success",
          title: result.message,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: result.message,
        });
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsEditing(false);
      fetchData();
    }
  };

  useEffect(() => {
    fetchData();
  }, [employee_id]);

  const [initialData, setInitialData] = useState({});
  const [formData, setFormData] = useState({
    employeeName: "",
    employee_image: "",
    employeeJobtitle: "",
    employeeDepartment: "",
    employeeEmail: "",
    employeeID: "",
    employeePhone: "",
    employeeBrd: "",
    employeeGender: "",
    employeeMStatus: "",
    employeeNat: "",
    employeeManager: "",
    employeeJoindate: "",
    employeeWemail: "",
    employeeWphone: "",
    employeeEduList: [],
    street: "",
    street2: "",
    city: "",
    state: "",
    state_list: [],
    zip: "",
  });

  // console.log(
  //   "formData.employee_image.startsWith('data:image/') ======= ",
  //   formData.employee_image.startsWith("data:image/")
  // );

  //   const employee_image = resultData.employee_image || "";
  const [isEditing, setIsEditing] = useState(false);

  const handleChange = (e) => {
    // console.log("called onchange");
    const { name, files, value } = e.target;
    if (e.target.name === "employee_image") {
      const attachment = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Data = reader.result;
        // console.log("base64Data", base64Data);
        setFormData({
          ...formData,
          [name]: base64Data,
        });
      };

      reader.readAsDataURL(attachment);
    } else {
      setFormData({ ...formData, [name]: e.target.value });
    }
  };

  // const [employeeId, setEmployeeId] = useState('123567');

  const handleEditClick = () => {
    setIsEditing(true);
  };
  const handleSaveClick = () => {
    setIsEditing(false);
    fetchData();
  };

  // console.log("formData.employeeEduList =========", formData.employeeEduList);

  return (
    <>
      {load ? (
        <div className="main-panel">
          <Asset_loading />
        </div>
      ) : (
        <div className="main-panel">
          <div className="profile-details">
            <div className="container-fluid">
              <div className="hrms-profile">
                <a className="back-icon" onClick={() => navigate(-1)}>
                  <i className="fas fa-angle-left" />
                </a>
                <div className="hrms-profile-avtr">
                  {isEditing ? (
                    <>
                      <div className="img-container">
                        <img
                          src={
                            formData?.employee_image?.startsWith("data:image/")
                              ? formData.employee_image
                              : `data:image/jpeg;base64,${atob(
                                  formData.employee_image
                                )}`
                          }
                          alt="Employee pic"
                        />
                        <label className="edit-icon">
                          <i className="fas fa-pencil-alt"></i>
                          <input
                            type="file"
                            name="employee_image"
                            accept="image/*"
                            onChange={handleChange}
                          />
                        </label>
                      </div>
                    </>
                  ) : (
                    <img
                      src={
                        formData?.employee_image?.startsWith("data:image/")
                          ? formData.employee_image
                          : `data:image/jpeg;base64,${atob(
                              formData.employee_image
                            )}`
                      }
                      alt="Employee pic"
                    />
                  )}
                </div>
                <div className="hrms-profile-name" style={{ color: textColor }}>
                  <span className="hrmsp-name">{formData.employeeName}</span>
                  <div>
                    <span className="hrms-badge">
                      {formData.employeeJobtitle}
                    </span>
                    <span className="hrms-badge">
                      {formData.employeeDepartment}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-9">
                  <div className="avtr-info" ref={jobRef}>
                    <div className="avtr-gen" style={{ color: textColor }}>
                      <h2>
                        <i className="fa-solid fa-suitcase" /> Job
                      </h2>
                    </div>
                    <div className="avtr-per">
                      <div
                        className=""
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          className="avtr-editt"
                          style={{ color: textColor }}
                        >
                          <h3>Employment Information</h3>
                        </div>
                      </div>
                      <table
                        className="avtr-table"
                        style={{
                          tableLayout: "fixed",
                          width: "-webkit-fill-available",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td>
                              <table
                                className="avtr-table"
                                style={{
                                  tableLayout: "fixed",
                                  width: "-webkit-fill-available",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        textAlign: "left",
                                        color: "gray",
                                        width: "25%",
                                      }}
                                    >
                                      Job Title
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "left",
                                        color: "#000",
                                        width: "75%",
                                      }}
                                    >
                                      {formData.employeeJobtitle}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        textAlign: "left",
                                        color: "gray",
                                        width: "25%",
                                      }}
                                    >
                                      Department
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "left",
                                        color: "#000",
                                        width: "75%",
                                      }}
                                    >
                                      {formData.employeeDepartment}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        textAlign: "left",
                                        color: "gray",
                                        width: "25%",
                                      }}
                                    >
                                      Work Email
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "left",
                                        color: "#000",
                                        width: "75%",
                                      }}
                                    >
                                      {formData.employeeWemail}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <table
                                className="avtr-table"
                                style={{
                                  tableLayout: "fixed",
                                  width: "-webkit-fill-available",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        textAlign: "left",
                                        color: "gray",
                                        width: "40%",
                                      }}
                                    >
                                      Joining Date
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "left",
                                        color: "#000",
                                      }}
                                    >
                                      {formData.employeeJoindate}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        textAlign: "left",
                                        color: "gray",
                                        width: "40%",
                                      }}
                                    >
                                      Manager
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "left",
                                        color: "#000",
                                      }}
                                    >
                                      {formData.employeeManager}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        textAlign: "left",
                                        color: "gray",
                                        width: "25%",
                                      }}
                                    >
                                      Work Phone
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "left",
                                        color: "#000",
                                        width: "75%",
                                      }}
                                    >
                                      {formData.employeeWphone}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className={`avtr-info ${highlight ? "highlight" : ""}`}>
                    <div className="avtr-gen" style={{ color: textColor }}>
                      <h2>
                        <i className="fa-solid fa-newspaper" /> General
                        Information
                      </h2>
                    </div>
                    <div ref={generalRef} className="avtr-per">
                      <div
                        className=""
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          className="avtr-editt"
                          style={{ color: textColor }}
                        >
                          <h3>Personal Information</h3>
                        </div>
                        {!isEditing && (
                          <div
                            className="avtr-editt"
                            role="button"
                            onClick={handleEditClick}
                            style={{ color: textColor }}
                          >
                            <i className="far fa-edit" /> Edit
                          </div>
                        )}
                        {isEditing && (
                          <div
                            className="avtr-editt"
                            style={{ color: textColor }}
                          >
                            <span
                              role="button"
                              onClick={updateData}
                              className="mr-2"
                            >
                              <i className="far fa-save" /> Save
                            </span>
                            <span
                              role="button"
                              onClick={() => {
                                setIsEditing(false);
                              }}
                              className="mr-2"
                            >
                              <i className="far fa-save" /> Cancel
                            </span>
                          </div>
                        )}
                      </div>
                      <table
                        className="avtr-table"
                        style={{
                          tableLayout: "fixed",
                          width: "-webkit-fill-available",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td style={{ width: "49%" }}>
                              <table
                                className="avtr-table"
                                style={{
                                  tableLayout: "fixed",
                                  width: "-webkit-fill-available",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        textAlign: "left",
                                        color: "gray",
                                      }}
                                    >
                                      <label>Employee ID</label>
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "left",
                                        color: "#000",
                                      }}
                                    >
                                      <span>{formData.employeeID}</span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        textAlign: "left",
                                        color: "gray",
                                        width: "25%",
                                      }}
                                    >
                                      <label htmlFor="employeePhone">
                                        Phone
                                      </label>
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "left",
                                        color: "#000",
                                        width: "75%",
                                      }}
                                    >
                                      {!isEditing ? (
                                        <span>{formData.employeePhone}</span>
                                      ) : (
                                        <input
                                          type="tel"
                                          id="employeePhone"
                                          name="employeePhone"
                                          value={formData.employeePhone || ""}
                                          onChange={handleChange}
                                          placeholder="Enter Phone Number"
                                        />
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        textAlign: "left",
                                        color: "gray",
                                        width: "25%",
                                      }}
                                    >
                                      <label htmlFor="employeeEmail">
                                        Email
                                      </label>
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "left",
                                        color: "#000",
                                        width: "75%",
                                      }}
                                    >
                                      {!isEditing ? (
                                        <span>{formData.employeeEmail}</span>
                                      ) : (
                                        <input
                                          type="email"
                                          id="employeeEmail"
                                          name="employeeEmail"
                                          value={formData.employeeEmail || ""}
                                          onChange={handleChange}
                                          placeholder="Enter email"
                                        />
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <table
                                className="avtr-table"
                                style={{
                                  tableLayout: "fixed",
                                  width: "-webkit-fill-available",
                                }}
                              >
                                <tbody>
                                  {/* <tr>
                                  <td
                                    style={{
                                      textAlign: "left",
                                      color: "gray",
                                      width: "40%"
                                    }}
                                  >
                                    Job ID
                                  </td>
                                  <td style={{ textAlign: "left", color: "#000" }}>
                                    123567
                                  </td>
                                </tr> */}
                                  <tr>
                                    <td
                                      style={{
                                        textAlign: "left",
                                        color: "gray",
                                        width: "40%",
                                      }}
                                    >
                                      <label htmlFor="employeeBrd">
                                        Birthday
                                      </label>
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "left",
                                        color: "#000",
                                      }}
                                    >
                                      {!isEditing ? (
                                        <span>{formData.employeeBrd}</span>
                                      ) : (
                                        <input
                                          type="date"
                                          id="employeeBrd"
                                          name="employeeBrd"
                                          value={formData.employeeBrd || ""}
                                          onChange={handleChange}
                                          placeholder="Enter Birthday"
                                        />
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        textAlign: "left",
                                        color: "gray",
                                        width: "40%",
                                      }}
                                    >
                                      <label htmlFor="employeeGender">
                                        Gender
                                      </label>
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "left",
                                        color: "#000",
                                      }}
                                    >
                                      {
                                        formData.employeeGender === "male"
                                          ? "Male"
                                          : formData.employeeGender === "female"
                                          ? "Female"
                                          : formData.employeeGender === "other"
                                          ? "Other"
                                          : formData.employeeGender // Default value if none of the above conditions match
                                      }
                                      {/* {!isEditing ? (
                                      <span>
                                        {
                                          formData.employeeGender === "male"
                                            ? "Male"
                                            : formData.employeeGender ===
                                              "female"
                                            ? "Female"
                                            : formData.employeeGender ===
                                              "other"
                                            ? "Other"
                                            : formData.employeeGender // Default value if none of the above conditions match
                                        }
                                      </span>
                                    ) : (
                                      <select
                                        id="employeeGender"
                                        name="employeeGender"
                                        value={formData.employeeGender}
                                        onChange={handleChange}
                                      >
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                        <option value="other">Other</option>
                                      </select>
                                    )} */}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        textAlign: "left",
                                        color: "gray",
                                        width: "40%",
                                      }}
                                    >
                                      <label htmlFor="employeeMStatus">
                                        Marital Status
                                      </label>
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "left",
                                        color: "#000",
                                      }}
                                    >
                                      {
                                        formData.employeeMStatus === "single"
                                          ? "Single"
                                          : formData.employeeMStatus ===
                                            "married"
                                          ? "Married"
                                          : formData.employeeMStatus ===
                                            "cohabitant"
                                          ? "Legal Cohabitant"
                                          : formData.employeeMStatus ===
                                            "widower"
                                          ? "Widower"
                                          : formData.employeeMStatus ===
                                            "divorced"
                                          ? "Divorced"
                                          : formData.employeeMStatus // Default value if none of the above conditions match
                                      }
                                      {/* {!isEditing ? (
                                      <span>
                                        {
                                          formData.employeeMStatus === "single"
                                            ? "Single"
                                            : formData.employeeMStatus ===
                                              "married"
                                            ? "Married"
                                            : formData.employeeMStatus ===
                                              "cohabitant"
                                            ? "Legal Cohabitant"
                                            : formData.employeeMStatus ===
                                              "widower"
                                            ? "Widower"
                                            : formData.employeeMStatus ===
                                              "divorced"
                                            ? "Divorced"
                                            : formData.employeeMStatus // Default value if none of the above conditions match
                                        }
                                      </span>
                                    ) : (
                                      <select
                                        id="employeeMStatus"
                                        name="employeeMStatus"
                                        value={formData.employeeMStatus}
                                        onChange={handleChange}
                                      >
                                        <option value="single">Single</option>
                                        <option value="married">Married</option>
                                        <option value="cohabitant">
                                          Legal Cohabitant
                                        </option>
                                        <option value="widower">Widower</option>
                                        <option value="divorced">
                                          Divorced
                                        </option>
                                      </select>
                                    )} */}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        textAlign: "left",
                                        color: "gray",
                                        width: "40%",
                                      }}
                                    >
                                      <label htmlFor="employeeNat">
                                        Nationality
                                      </label>
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "left",
                                        color: "#000",
                                      }}
                                    >
                                      <span>{formData.employeeNat}</span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="avtr-hr" />
                    <div className="avtr-per">
                      <div
                        className=""
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          className="avtr-editt"
                          style={{ color: textColor }}
                        >
                          <h3>Address Information</h3>
                        </div>
                      </div>
                      <table
                        className="avtr-table"
                        style={{
                          tableLayout: "fixed",
                          width: "-webkit-fill-available",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td>
                              <table
                                className="avtr-table"
                                style={{
                                  tableLayout: "fixed",
                                  width: "-webkit-fill-available",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        textAlign: "left",
                                        color: "gray",
                                        width: "25%",
                                      }}
                                    >
                                      <label htmlFor="employeeAddress">
                                        Address
                                      </label>
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "left",
                                        color: "#000",
                                        width: "75%",
                                      }}
                                    >
                                      {!isEditing ? (
                                        <>
                                          <div className="address-form">
                                            <div>{formData.street}</div>
                                            <div>{formData.street2}</div>
                                            <div className="city-state-zip">
                                              {formData.city} {formData.state}{" "}
                                              {formData.zip}
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div className="address-form">
                                            <div>
                                              <input
                                                type="text"
                                                id="street"
                                                name="street"
                                                value={formData.street || ""}
                                                onChange={handleChange}
                                                placeholder="Enter Street 1"
                                              />
                                            </div>

                                            <div>
                                              <input
                                                type="text"
                                                id="street2"
                                                name="street2"
                                                value={formData.street2 || ""}
                                                onChange={handleChange}
                                                placeholder="Enter Street 2"
                                              />
                                            </div>

                                            <div className="city-state-zip">
                                              <input
                                                type="text"
                                                id="city"
                                                name="city"
                                                value={formData.city || ""}
                                                onChange={handleChange}
                                                placeholder="Enter City"
                                              />
                                              <select
                                                id="state"
                                                name="state"
                                                value={formData.state || ""}
                                                onChange={handleChange}
                                              >
                                                <option value="">
                                                  Select State
                                                </option>
                                                {formData.state_list.map(
                                                  (state, index) => (
                                                    <option
                                                      key={index}
                                                      value={state[1]}
                                                    >
                                                      {state[0]}
                                                    </option>
                                                  )
                                                )}
                                              </select>
                                              <input
                                                type="text"
                                                id="zip"
                                                name="zip"
                                                value={formData.zip || ""}
                                                onChange={handleChange}
                                                placeholder="Enter Zip Code"
                                              />
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="avtr-hr" />
                    <div className="avtr-per">
                      <div
                        className=""
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          className="avtr-editt"
                          style={{ color: textColor }}
                        >
                          <h3>Education</h3>
                        </div>
                      </div>
                      <table
                        className="avtr-table"
                        style={{
                          tableLayout: "fixed",
                          width: "-webkit-fill-available",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td>
                              <table
                                className="avtr-table"
                                style={{
                                  tableLayout: "fixed",
                                  width: "-webkit-fill-available",
                                }}
                              >
                                <tbody>
                                  {formData.employeeEduList.map(
                                    (item, index) => (
                                      <tr key={index}>
                                        <td
                                          style={{
                                            textAlign: "left",
                                            color: "gray",
                                            width: "25%",
                                          }}
                                        >
                                          {item['year']}{" "}
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "left",
                                            color: "#000",
                                            // width: "75%",
                                          }}
                                        >
                                          {item['grade']}{" "}
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "left",
                                            color: "#000",
                                            // width: "75%",
                                          }}
                                        >
                                          {item['field']}{" "}
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "left",
                                            color: "#000",
                                            width: "75%",
                                            display:'flex',
                                            justifyContent:'space-around',
                                            gap:10,
                                            marginTop: 10
                                          }}
                                        >
                                          {item['certificates']?.map((pdf, index) => (
                                            <span key={index}>
                                              <Base64Viewer base64Code={pdf.upload_certificate}  fileType={pdf.type} fileName={pdf.name}/>
                                            </span>
                                          ))}{" "}
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 myprofile-sidebar">
                  <div
                    className={`avtr-info ${
                      activeMenu === "job" ? "active" : ""
                    }`}
                  >
                    <div
                      className="avtr-gen"
                      role="button"
                      onClick={handleJobClick}
                      style={{ color: textColor }}
                    >
                      <h2>
                        <i className="fa-solid fa-suitcase" /> Job
                      </h2>
                    </div>
                  </div>
                  <div
                    className={`avtr-info ${
                      activeMenu === "general" ? "active" : ""
                    }`}
                  >
                    <div
                      className="avtr-gen"
                      role="button"
                      onClick={handlegenClick}
                      style={{ color: textColor }}
                    >
                      <h2>
                        <i className="fa-solid fa-newspaper" /> General
                        Information
                      </h2>
                    </div>
                  </div>
                  {/* <div className="avtr-info">
                  <div className="avtr-gen">
                    <h2>
                      <i className="fas fa-file-alt" /> General Information
                    </h2>
                  </div>
                </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Profile;
