import { MultiSelect } from "primereact/multiselect";
import { CustomerService } from "./service/CustomerService";

import React, {
  useState,
  useContext,
  useEffect,
} from "react";
import HrDashboard_loading from "../HrDashboard/HrDashboard_loading";
import { EmplContext } from "../Login_Page/Login_Page/LoginPage";
import configData from "../../../src/config.json";
import Swal from "sweetalert2";
import { ColorContext } from "../../ColorContext";
import "./ManagerDasboard.css";

export default function ManagerDashboard() {
  const [customers, setCustomers] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});

  const [requestedleaves, setRequestedleaves] = useState([]);

  const { resultData } = useContext(EmplContext);
  const { textColor } = useContext(ColorContext);
  const employee_id = resultData.employee_id || 0;

  const [load, setLoading] = useState(true);
  const [showFilter, setShowFilter] = useState(true);
  const [dateGrouping, setDateGrouping] = useState({});

  const [groupByDateFields, setGroupByDateFields] = useState([]); // For the "Start Date" grouping
  const [groupByOtherFields, setGroupByOtherFields] = useState([]); // For the "Others" grouping

  function showFilterS() {
    setShowFilter(!showFilter);
  }

  const handleLeaveAction = async (leave, action) => {
    try {
      const secretKey = configData.SECRET_Key;
      const requestData = { employee_id, secretKey, leave, action };
      const response = await fetch(`${configData.SERVER_URL}/handleleave`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
      const data = await response.json();
      const result = JSON.parse(data.result);
      // console.log("result", result);
      if (result.success === true) {
        Swal.fire({
          icon: "success",
          title: result.message,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: result.message,
        });
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      fetchData();
    }
  };

  const availableGroupByFields = [
    {
      label: "Start Date", // This is the group label
      items: [
        // These are the group children (sub-options)
        { label: "Year", value: "date_from_year" },
        { label: "Quarter", value: "date_from_quarter" },
        { label: "Month", value: "date_from_month" },
        { label: "Week", value: "date_from_week" },
        { label: "Day", value: "date_from_day" },
      ],
    },
  ];

  const availableGroupByFields2 = [
    { label: "Status", value: "status" },
    { label: "Name", value: "name" },
    { label: "Description", value: "description" },
  ];

  // useEffect(() => {
  //     CustomerService.getCustomersMedium().then((data) => setCustomers(data || [])); // Ensure customers is an array
  // }, []);

  const fetchData = async () => {
    const employee_id = resultData.employee_id || 0;
    const secretKey = configData.SECRET_Key;

    try {
      const data = await CustomerService.getCustomersMedium(
        employee_id,
        secretKey
      );
      setCustomers(data.all_leaves_list);
      setRequestedleaves(data.all_leaves_request_list);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: error,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [resultData.employee_id]);

  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split("-").map(Number);

    const date = new Date(2000 + year, month - 1, day);

    return date;
  };

  const handleGroupByChange = (e) => {
    const selectedFields = e.value;

    // Create a mapping for date groupings (e.g., "date_from_year" => "year")
    const newDateGrouping = {};
    // console.log("selectedFields === ", selectedFields);

    selectedFields.forEach((field) => {
      // console.log("field === ", field, typeof field); // Ensure field is a string

      // Check for exact matches with field (since it's a string)
      if (field === "date_from_year") newDateGrouping[field] = "year";
      if (field === "date_from_quarter") newDateGrouping[field] = "quarter";
      if (field === "date_from_month") newDateGrouping[field] = "month";
      if (field === "date_from_week") newDateGrouping[field] = "week";
      if (field === "date_from_day") newDateGrouping[field] = "day";

      if (field === "date_to_year") newDateGrouping[field] = "year";
      if (field === "date_to_quarter") newDateGrouping[field] = "quarter";
      if (field === "date_to_month") newDateGrouping[field] = "month";
      if (field === "date_to_week") newDateGrouping[field] = "week";
      if (field === "date_to_day") newDateGrouping[field] = "day";
    });

    setGroupByDateFields(selectedFields); // No need to map, since field is already a string
    setDateGrouping(newDateGrouping); // Update the date grouping map
  };

  const formatByDateGrouping = (date, grouping) => {
    switch (grouping) {
      case "year":
        return date.getFullYear();
      case "quarter":
        return `Q${Math.floor(date.getMonth() / 3) + 1}`;
      case "month":
        return date.toLocaleString("default", { month: "long" });
      case "week":
        const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
        const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
        return `Week ${Math.ceil(
          (pastDaysOfYear + firstDayOfYear.getDay()) / 7
        )}`;
      case "day":
        return date.toLocaleDateString();
      default:
        return date.toLocaleDateString();
    }
  };

  // Recursive function to create grouped data
  const createGroupedData = (data, groupFields, dateGrouping = {}) => {
    if (!data || !groupFields || groupFields.length === 0) return data;

    const groupedData = data.reduce((acc, item) => {
      // console.log("acc, item ",acc, item)
      let groupKey = groupFields[0]
        .split(".")
        .reduce((obj, key) => (obj && obj[key] ? obj[key] : null), item);
      // console.log("groupFields==",groupFields)
      // console.log("groupFields[0].split('.')===",groupFields[0].split("."))

      if (groupFields[0].includes("date") && dateGrouping[groupFields[0]]) {
        const dateValue = new Date(parseDate(item["date_from"]));

        // console.log("dateValue===",dateValue)
        // console.log("item ==",item)
        const date = dateValue;
        // console.log("dateGrouping[groupFields[0]]===",dateGrouping[groupFields[0]])
        // console.log(" groupByFields, dateGrouping ,groupKey , date==", groupByFields, dateGrouping, groupKey, date)

        groupKey = formatByDateGrouping(date, dateGrouping[groupFields[0]]);
      }

      if (!groupKey) return acc; // Skip if groupKey is undefined or null

      if (!acc[groupKey]) {
        acc[groupKey] = { key: groupKey, items: [] };
      }
      acc[groupKey].items.push(item);
      return acc;
    }, {});

    Object.values(groupedData).forEach((group) => {
      group.items =
        createGroupedData(group.items, groupFields.slice(1), dateGrouping) ||
        []; // Ensure items is always an array
    });

    return Object.values(groupedData);
  };

  const combinedGroupByFields = [...groupByDateFields, ...groupByOtherFields];


  const groupedCustomers =
  combinedGroupByFields.length > 0
      ? createGroupedData(customers, combinedGroupByFields, dateGrouping)
      : customers;

  const toggleRow = (rowKey) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [rowKey]: !prevExpandedRows[rowKey],
    }));
  };

  // Render grouped rows recursively
  const renderRowGroup = (groupData, depth = 0) => {
    if (!groupData || groupData.length === 0) return null;

    return groupData.map((group, index) => (
      <React.Fragment key={group.key + index}>
        <tr
          onClick={() => toggleRow(group.key)}
          style={{ backgroundColor: depth % 2 === 0 ? "#f9f9f9" : "#e9e9e9" }}
        >
          <td role="button" colSpan="5">
            <span style={{ marginLeft: `${depth * 20}px`, fontWeight: "bold" }}>
              {group.key} ({group.items?.length || 0})
            </span>
          </td>
        </tr>
        {expandedRows[group.key] &&
        group.items &&
        group.items.length > 0 &&
        group.items[0]?.key
          ? renderRowGroup(group.items, depth + 1)
          : expandedRows[group.key] &&
            group.items &&
            group.items.map((item, idx) => (
              <tr key={item.name + idx}>
                <td>{item.name}</td>
                <td>{item.date_from}</td>
                <td>{item.date_to}</td>
                <td>{item.status}</td>
                <td>{item.description}</td>
              </tr>
            ))}
      </React.Fragment>
    ));
  };

  // Render flat table rows when no grouping is applied
  const renderFlatRows = (data) => {
    return data.map((item, idx) => (
      <tr key={item.name + idx}>
        <td>{item.name}</td>
        <td>{item.date_from}</td>
        <td>{item.date_to}</td>
        <td>{item.status}</td>
        <td>{item.description}</td>
      </tr>
    ));
  };

  const groupedItemTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <div>{option.label}</div>
      </div>
    );
  };

  return (
    <>
      <div className="logo-hr-dashboard">
        <img
          src="company_logo.png"
          alt="Profile Pic"
          className="img-xs brand-logo-image "
        />
      </div>
      {load ? (
        <div className="">
          <HrDashboard_loading />
        </div>
      ) : (
        <div className="content-wrapper" >
          <div className="ep_content">
            <div className="row">
              {/* <div className="col-md-12">
                <div className="al_holi">
                  <h2 className="al_holi_tl">All Leave Request</h2>
                </div>
                <div className="">
                  <div className="atable">
                    <table id="basicTable" className="basic-table">
                      <thead className="all-tables-head">
                        <tr role="row" style={{ backgroundColor: textColor }}>
                          <th className="center">
                            <span> S.No </span>
                            <span />
                          </th>
                          <th className="center">
                            <span> Team Member </span>
                            <span />
                          </th>
                          <th className="center">
                            <span> Date From </span>
                            <span />
                          </th>
                          <th className="center">
                            <span> Date To </span>
                            <span />
                          </th>
                          <th className="center">
                            <span> Status </span>
                            <span />
                          </th>
                          <th className="center">
                            <span> Remark </span>
                            <span />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {requestedleaves.map((leave, idx) => (
                          <tr key={idx} className="">
                            <td>{idx + 1}</td>
                            <td>{leave[0]}</td>
                            <td>{leave[1]}</td>
                            <td>{leave[2]}</td>
                            <td>
                              <button
                                className="btn btn-primary m-3"
                                onClick={() =>
                                  handleLeaveAction(leave[5], "approve")
                                }
                              >
                                Approve
                              </button>
                              <button
                                className="btn btn-secondary m-3"
                                onClick={() =>
                                  handleLeaveAction(leave[5], "reject")
                                }
                              >
                                Reject
                              </button>
                            </td>
                            <td>{leave[4]}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div> */}

              <div className="col-md-12">
                <div className="al_holi mt-5">
                  <h2 className="al_holi_tl"> All Leaves </h2>
                </div>

                <div className="card">
                  <div
                    className="showFilter-btn groupby-btn m-3"
                    onClick={showFilterS}
                  >
                    <img
                      src="filter.jpg"
                      alt="filter pic"
                      style={{
                        height: 15,
                        margin: "7px 0px 10px 16px",
                      }}
                    />
                    <p
                      htmlFor="options"
                      style={{
                        marginBottom: "0px",
                        lineHeight: "29px",
                      }}
                    >
                      Groupby
                    </p>
                  </div>

                  <div
                    className={`das-filtitle m-3 ${
                      showFilter ? "show-filter" : ""
                    }`}
                  >
                    <label htmlFor="groupBy">Group By</label>
                    <div className="card flex justify-content-center">
                      <MultiSelect
                        id="groupBy"
                        className="groupby-multiSelect"
                        value={groupByDateFields}
                        options={availableGroupByFields}
                        onChange={handleGroupByChange}
                        placeholder="Start Date"
                        display="chip"
                        optionLabel="label"
                        optionGroupLabel="label"
                        optionGroupChildren="items"
                        optionGroupTemplate={groupedItemTemplate}
                      />
                    </div>
                    <div className="card flex justify-content-center">
                      <MultiSelect
                        id="groupBy2"
                        className="groupby-multiSelect"
                        value={groupByOtherFields}
                        options={availableGroupByFields2}
                        onChange={(e) => setGroupByOtherFields(e.value || [])}
                        placeholder="Others"
                        display="chip"
                      />
                    </div>
                  </div>
                  <table className="custom-datatable">
                    <thead>
                      <tr
                        style={{ backgroundColor: textColor, color: "white" }}
                      >
                        <th>Name</th>
                        <th>Date From</th>
                        <th>Date To</th>
                        <th>Status</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {combinedGroupByFields.length > 0
                        ? renderRowGroup(groupedCustomers)
                        : renderFlatRows(customers)}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}