import { MultiSelect } from "primereact/multiselect";
import { CustomerService } from "./service/CustomerService";

import React, {
  useState,
  useContext,
  useEffect,
} from "react";
import HrDashboard_loading from "../HrDashboard/HrDashboard_loading";
import { EmplContext } from "../Login_Page/Login_Page/LoginPage";
import configData from "../../../src/config.json";
import Swal from "sweetalert2";
import { ColorContext } from "../../ColorContext";
import "./ManagerDasboard.css";

export default function TeamExpenses() {
  const [customers, setCustomers] = useState([]);
  const [requestedleaves, setRequestedleaves] = useState([]);

  const { resultData } = useContext(EmplContext);
  const { textColor } = useContext(ColorContext);
  const employee_id = resultData.employee_id || 0;

  const [load, setLoading] = useState(true);

  const handleLeaveAction = async (leave, action) => {
    try {
      const secretKey = configData.SECRET_Key;
      const requestData = { employee_id, secretKey, leave, action };
      const response = await fetch(`${configData.SERVER_URL}/handleleave`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
      const data = await response.json();
      const result = JSON.parse(data.result);
      // console.log("result", result);
      if (result.success === true) {
        Swal.fire({
          icon: "success",
          title: result.message,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: result.message,
        });
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      fetchData();
    }
  };
  const fetchData = async () => {
    const employee_id = resultData.employee_id || 0;
    const secretKey = configData.SECRET_Key;

    try {
      const data = await CustomerService.getCustomersMedium(
        employee_id,
        secretKey
      );
      setCustomers(data.all_leaves_list);
      setRequestedleaves(data.all_leaves_request_list);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: error,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [resultData.employee_id]);


  return (
    <>
      <div className="logo-hr-dashboard">
        <img
          src="company_logo.png"
          alt="Profile Pic"
          className="img-xs brand-logo-image "
        />
      </div>
      {load ? (
        <div className="">
          <HrDashboard_loading />
        </div>
      ) : (
        <div className="content-wrapper" >
          <div className="ep_content">
            <div className="row" style={{paddingTop: 50}}>
              <div className="col-md-12">
                <div className="al_holi">
                  <h2 className="al_holi_tl">Team Expenses</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}