import React, { useState, useContext, useEffect } from "react";
import { EmplContext } from "../Login_Page/Login_Page/LoginPage";
import configData from "../../../src/config.json";
import { ColorContext } from "../../ColorContext";
import LoadingComponent from "../loading_component";

function Holiday() {
  const { resultData } = useContext(EmplContext);
  const employee_id = resultData.employee_id || 0;
  const [Holidays, setHolidays] = useState([]);
  const { textColor } = useContext(ColorContext);
  const [load, setLoading] = useState(true);

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        const secretKey = configData.SECRET_Key;
        const requestData = { employee_id, secretKey };
        const response = await fetch(`${configData.SERVER_URL}/myholidays`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        });
        const data = await response.json();
        const result = JSON.parse(data.result);
        if (result.success === true) {
          setHolidays(result.holidays_record);
        } else {
          console.log("result ===", result);
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCompanyDetails();
  }, [employee_id]);

  // Split Holidays based on holiday_type
  const publicHolidays = Holidays.filter((holiday) => holiday[2] === "public");
  const regionalHolidays = Holidays.filter((holiday) => holiday[2] === "rh");

  return (
    <>
      {load ? (
        <div className="main-panel">
          <LoadingComponent />
        </div>
      ) : (
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="ep_content">
              <div className="row">
                <div className="col-md-12">
                  {/* Public Holidays Section */}
                  <div className="al_holi">
                    <h2 className="al_holi_tl">Public Holiday</h2>
                  </div>
                  <div className="atable">
                    <table id="basicTable" className="basic-table">
                      <thead>
                        <tr
                          role="row"
                          style={{
                            backgroundColor: textColor,
                            color: "#FFF",
                          }}
                        >
                          <th className="center">
                            <span> Date </span>
                          </th>
                          <th className="center">
                            <span> Holiday </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {publicHolidays.length > 0 ? (
                          publicHolidays.map((holiday, index) => (
                            <tr className="holiday_table_row" key={index}>
                              <td className="">{holiday[0]}</td>
                              <td className="">{holiday[1]}</td>
                            </tr>
                          ))
                        ) : (
                          <tr className="holiday_table_row">
                            <td
                              className=""
                              style={{ textAlign: "center" }}
                              colSpan={2}
                            >
                              <h3>No Upcoming Holiday</h3>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  {/* Regional Holidays Section */}
                  <div className="al_holi">
                    <h2 className="al_holi_tl">Regional Holiday</h2>
                  </div>
                  <div className="atable">
                    <table id="basicTable" className="basic-table">
                      <thead>
                        <tr
                          role="row"
                          style={{
                            backgroundColor: textColor,
                            color: "#FFF",
                          }}
                        >
                          <th className="center">
                            <span> Date </span>
                          </th>
                          <th className="center">
                            <span> Regional Holiday </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {regionalHolidays.length > 0 ? (
                          regionalHolidays.map((holiday, index) => (
                            <tr className="holiday_table_row" key={index}>
                              <td className="">{holiday[0]}</td>
                              <td className="">{holiday[1]}</td>
                            </tr>
                          ))
                        ) : (
                          <tr className="holiday_table_row">
                            <td
                              className=""
                              style={{ textAlign: "center" }}
                              colSpan={2}
                            >
                              <h3>No Regional Holidays</h3>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Holiday;
